import {
  menu_default
} from "../../chunks/chunk.RPIO5J3V.js";
import "../../chunks/chunk.F2NECMQL.js";
import "../../chunks/chunk.VVA35HTY.js";
import "../../chunks/chunk.TUVJKY7S.js";
import "../../chunks/chunk.SFSTXCXC.js";
import "../../chunks/chunk.IFDWM6P4.js";
export {
  menu_default as default
};
