import {
  SlProgressBar
} from "./chunk.2S7DHWQZ.js";

// src/components/progress-bar/progress-bar.ts
var progress_bar_default = SlProgressBar;
SlProgressBar.define("sl-progress-bar");

export {
  progress_bar_default
};
