import {
  SlSelect
} from "./chunk.XHABG7B5.js";

// src/components/select/select.ts
var select_default = SlSelect;
SlSelect.define("sl-select");

export {
  select_default
};
