import {
  SlDialog
} from "./chunk.UE77SM53.js";

// src/components/dialog/dialog.ts
var dialog_default = SlDialog;
SlDialog.define("sl-dialog");

export {
  dialog_default
};
