import {
  SlDropdown
} from "./chunk.FLYTFYGC.js";

// src/components/dropdown/dropdown.ts
var dropdown_default = SlDropdown;
SlDropdown.define("sl-dropdown");

export {
  dropdown_default
};
